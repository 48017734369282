import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../utils/baseUrl";

export const retrieveContactsData = createAsyncThunk(
    'contacts/retrieveContactsData',
    async (rejectWithValue) => {
        try {
            const response = await axios.get(`${API_BASE}/api/data/contacts`);
            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response ? error.response.data : "Unknown error"
            )
        }
    }
)

export const postContactsData = createAsyncThunk(
    'contacts/postContactsData',
    async (contactsData, thunkApi) => {
        try{
            const response = await axios.post(`${API_BASE}/api/contact/send`, contactsData);
            return response.data
        } catch (error){
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
)

const contactsSlice = createSlice({
    name: 'contacts',
    initialState:{
        isLoading: false,
        error: null,
        status: 'idle',
        contactsData: null
    },
    reducers:{
        resetFormState: (state) => {
            state.isLoading = false;
            state.success = false;
            state.error = null;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postContactsData.pending, (state) => {
                state.isLoading = true;
                state.status = "loading";
            })
            .addCase(postContactsData.fulfilled, (state) => {
                state.isLoading = false;
                state.status = "succeeded";
                state.success = true;
            })
            .addCase(postContactsData.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(retrieveContactsData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.status = 'loading';
            })
            .addCase(retrieveContactsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.status = 'succeeded';
                state.contactsData = action.payload;
            })
            .addCase(retrieveContactsData.rejected, (state, action) => {
                state.isLoading = false;
                state.status = 'failed';
                state.error = action.payload
            })
    }
})

export const {resetFormState} = contactsSlice.actions
export default contactsSlice.reducer