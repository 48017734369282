import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { postReviewsData, resetFormState } from "../../store/productsSlice";
import Button from "../button";
import contactUsBg from "../../assets/25.jpg";
import Checkbox from "../checkbox";
import "./styles.sass";

const ReviewForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, setValue } = useForm();
  const { loading, success, error } = useSelector((state) => state?.products);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (checked) => {
    setIsChecked(checked);
  };

  const onSubmit = (data) => {
    data.product_id = props?.productId;
    const formData = {
      ...data,
      news_allowed: isChecked ? "true" : "false",
    };
    dispatch(postReviewsData(formData));
  };

  useEffect(() => {
    if (success) {
      reset();
      dispatch(resetFormState());
      toast.success(`${t("blocks.toast.success")}`);
    }
  }, [success, dispatch, reset]);

  return (
    <section
      className='reviewForm'
      id='contact'
    >
      <div className='reviewFormImage'>
        <img
          src={contactUsBg}
          alt='contactUsBg'
          className='img'
        />
      </div>
      <form
        className='reviewFormContainer'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='container'>
          <div className='reviewFormContent'>
            <div className='reviewFormHeader'>
              <div className='reviewFormTitle'>
                <h3 className='title'>{t("blocks.reviews.feedback")}</h3>
              </div>
              <div className='reviewFormSubtitle'>
                <p className='subtitle'>{t("blocks.reviews.reviewSubtitle")}</p>
              </div>
            </div>
            <div className='reviewFormContainer'>
              <div className='reviewFormInputs'>
                <div className='reviewFormFields'>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    className='input'
                    required
                    {...register("name", { required: true })}
                    placeholder={`${t("blocks.contactUs.name")}`}
                  />
                </div>
                <div className='reviewFormFields'>
                  <input
                    type='email'
                    id='email'
                    name='email'
                    className='input'
                    required
                    {...register("email", { required: true })}
                    placeholder={`${t("blocks.contactUs.email")}`}
                  />
                </div>
                <div className='reviewFormFields'>
                  <input
                    type='text'
                    id='message'
                    name='message'
                    className='input'
                    required
                    {...register("message", { required: true })}
                    placeholder={`${t("blocks.contactUs.text")}`}
                  />
                </div>
              </div>
              <div className='reviewFormBtn'>
                <Button disabled={!isChecked}>{t("blocks.contactUs.submit")}</Button>
              </div>
            </div>
            <div className='reviewFormCheckbox'>
              <div className='fromCheck'>
                <Checkbox onChange={handleCheckboxChange} />
              </div>
              <div className='checkboxText'>
                <p className='text'>{t("blocks.contactUs.checkboxText")}</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default ReviewForm;
