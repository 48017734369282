import React, { useEffect } from "react";
import ProjectInnerHero from "../../components/projectInnerHero";
import ProjectContent from "../../components/projectContent";
import { useParams } from "react-router-dom";
import ProductInner from "../../components/productDescription";
import { useDispatch, useSelector } from "react-redux";
import { retrieveProductsData } from "../../store/productsSlice";
import ReviewsSlider from "../../components/reviewsSlider";
import ReviewForm from "../../components/reviewForm";

const ProductsInner = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { productsData, status, isLoading, error } = useSelector((state) => state?.products);
  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveProductsData());
    }
  }, [dispatch, status]);
  const productDataInner = productsData?.products;
  const newProductData = productDataInner?.find((product) => product?.id === Number(id));

  const productId = newProductData?.id;
  return (
    <div className='projectInner'>
      <ProjectInnerHero newProductData={newProductData} />
      <ProjectContent newProductData={newProductData} />
      {newProductData?.images?.length > 0 && <ProductInner newProductData={newProductData} />}
      <ReviewsSlider newProductData={newProductData} />
      <ReviewForm productId={productId} />
    </div>
  );
};

export default ProductsInner;
