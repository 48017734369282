import React, { useEffect } from "react";
import AboutHero from "../../components/aboutHero";
import AboutSecondBlock from "../../components/aboutSecondBlock";
import Development from "../../components/development";
import Certificates from "../../components/certificates";
import ContactUs from "../../components/contactUs";
import International from "../../components/international";
import { useDispatch, useSelector } from "react-redux";
import { retrieveAboutData } from "../../store/aboutSlice";

const AboutPage = () => {
  const dispatch = useDispatch();
  const { aboutData, status, isLoading, error } = useSelector((state) => state?.about);

  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveAboutData());
    }
  }, [dispatch, status]);

  const divided_section = aboutData?.divided_section;
  const main_section = aboutData?.main_section;
  const history_section = aboutData?.history_section;
  const international_section = aboutData?.international_section;
  const certificates_section = aboutData?.certificates_section;

  return (
    <div className='aboutPage'>
      <AboutHero main_section={main_section} />
      <AboutSecondBlock divided_section={divided_section} />
      <Development history_section={history_section} />
      <Certificates certificates_section={certificates_section} />
      <ContactUs />
      <International international_section={international_section} />
    </div>
  );
};

export default AboutPage;
