import React, {useRef} from "react";
import "./styles.sass";
import Plyr from "plyr-react"
// import Plyr from 'plyr';

import "plyr-react/plyr.css"
const YoutubeVideoComponent = ({className, videoId }) => {
    const plyrRef = useRef(null);
    // const player = new Plyr('#player', {
    //     controls: ['play', 'progress', 'mute', 'volume', 'fullscreen']
    // });
    const handleReady = (event) => {
        const player = event.detail.plyr;
        console.log('Plyr is ready!', player);

        player.muted = true; // Mute video
        // player.play();       // Play video
    };
    return (
        <div className="yt-video-container">
            {/*<div className="plyr__video-embed" id="player"*/}
            {/*     // style="position: relative; width: 100%; height: 500px; overflow: hidden;"*/}
            {/*>*/}
            {/*    <iframe*/}
            {/*        src="https://www.youtube.com/watch?v=6xZUzmTeo4o?rel=0&iv_load_policy=3"*/}
            {/*        allowFullScreen*/}
            {/*        allow="autoplay; encrypted-media"*/}
            {/*        id="some">*/}
            {/*    </iframe>*/}
            {/*</div>*/}
            <Plyr
                ref={plyrRef}
                source={{
                    type: 'video',
                    sources: [
                        {
                            src: videoId, // Replace with your YouTube video ID
                            provider: 'youtube',
                        },
                    ],
                }}
                // source={`https://www.youtube.com/watch?v=?rel=0&iv_load_policy=3`}
                options={{
                    controls: ['play', 'progress', 'mute', 'volume', 'fullscreen'],
                    youtube: {
                        origin: 'https://eascompany.uz',
                    },
                }}
                onReady={handleReady}

            />
            <div className={"overlay"}></div>
        </div>
    );
};

export default YoutubeVideoComponent;
