import React from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "../pages/main";
import AboutPage from "../pages/about";
import NewsPage from "../pages/news";
import Article from "../pages/article";
import ContactUs from "../components/contactUs";
import Blog from "../pages/blog";
import BlogInnerPage from "../pages/blogInner";
import Resources from "../pages/resources";
import ResourcesInner from "../pages/resourcesInner";
import Products from "../pages/products";
import ProductsInner from "../pages/productsInner";

export const Routing = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={<MainPage />}
      />
      <Route
        path='/about'
        element={<AboutPage />}
      />
      <Route
        path='/news'
        element={<NewsPage />}
      />
      <Route
        path='/news/:id'
        element={<Article />}
      />
      <Route
        path='/products'
        element={<Products />}
      />
      <Route
        path='/product/:id'
        element={<ProductsInner />}
      />
      <Route
        path='#contact-us'
        element={<ContactUs />}
      />
      <Route
        path='/blog'
        element={<Blog />}
      />
      <Route
        path='/blog/1'
        element={<BlogInnerPage />}
      />
      <Route
        path='/resources'
        element={<Resources />}
      />
      <Route
        path='/resourcesInner/:id'
        element={<ResourcesInner />}
      />
    </Routes>
  );
};
