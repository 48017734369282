import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.sass";

const ProjectsHero = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  return (
    <div className='projectHero'>
      {props?.imageBg && (
        <div className='projectHeroImage'>
          <img
            src={props?.imageBg}
            alt='aboutHero'
            className='img'
          />
        </div>
      )}
      <div className='projectHeroContent'>
        <div className='container'>
          <div className='contentContainer'>
            <div className='contentTitle'>
              <h2 className='title'> {t("blocks.product.title")}</h2>
            </div>
            {props?.heroText?.[lang] && (
              <div className='contentSubtitle'>
                <p className='subtitle'>{props?.heroText?.[lang]}</p>
                {/* <p className='subtitle'> {t("blocks.product.subtitle")}</p> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsHero;
