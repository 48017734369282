import React from "react";
import easBg from "../../assets/easBg.png";
import { useTranslation } from "react-i18next";
import "./styles.sass";

const ProjectInnerHero = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  return (
    <section className='projectInnerHero'>
      {props?.newProductData?.cover && (
        <div className='projectHeroImage'>
          <img
            src={props?.newProductData?.cover}
            alt='easBg'
            className='img'
          />
        </div>
      )}
      <div className='backdropFilter'></div>
      <div className='projectHeroContent'>
        <div className='container'>
          {props?.newProductData?.title?.[lang] && (
            <div className='projectHeroTitle'>
              <h3 className='title'>{props?.newProductData?.title?.[lang]}</h3>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProjectInnerHero;
