import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { FaRegUser } from "react-icons/fa6";
import "./styles.sass";
import { useTranslation } from "react-i18next";

const ReviewsSlider = (props) => {
  const { t } = useTranslation();
  return (
    <div className='reviews'>
      <div className='container'>
        {props?.newProductData?.feedbacks?.length > 0 && (
          <div className='reviewsContainer'>
            <div className='reviewsTitle'>
              <h4 className='title'>{t("blocks.reviews.reviews")}</h4>
            </div>
            <div className='reviewsSlider'>
              <Swiper
                spaceBetween={50}
                loop={true}
                autoplay={{ delay: 2000 }}
                navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
                freeMode={true}
                modules={[Autoplay, Navigation, Pagination]}
                speed={1200}
                className='reviewsSliderSwiper'
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                  1250: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                }}
              >
                {props?.newProductData?.feedbacks?.length > 4 && (
                  <div className='sliderHeader'>
                    <div className='btnsGroup'>
                      <div className='swiper-button-prev'>
                        <IoIosArrowUp />
                      </div>
                      <div className='swiper-pagination'></div>
                      <div className='swiper-button-next'>
                        <IoIosArrowDown />
                      </div>
                    </div>
                  </div>
                )}
                {props?.newProductData?.feedbacks?.length > 0 &&
                  props?.newProductData?.feedbacks.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className='reviewCard'>
                        <div className='cardContainer'>
                          <div className='cardText'>
                            <p className='text'>{item?.message}</p>
                          </div>
                          <div className='cardBottomContent'>
                            <div className='user'>
                              <div className='userIcon'>
                                <FaRegUser />
                              </div>
                              <div className='userName'>
                                <p className='name'>{item?.name}</p>
                              </div>
                            </div>
                            <div className='publishDate'>
                              <p className='date'>{item?.date}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewsSlider;
