import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.sass";

const ResourcesInnerHero = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  return (
    <div className='resourcesInnerHero'>
      <div className='resourcesInnerHeroImage'>
        {props?.resourcesHero?.cover && (
          <img
            src={props?.resourcesHero?.cover}
            alt='aboutHero'
            className='img'
          />
        )}
      </div>
      <div className='resourcesInnerHeroContent'>
        <div className='container'>
          <div className='contentContainer'>
            {props?.resourcesHero?.title?.[lang] && (
              <div className='contentTitle'>
                <h2 className='title'>{props?.resourcesHero?.title?.[lang]}</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesInnerHero;
