import React, { useEffect, useState } from "react";
import { newsData } from "../../data";
import NewsHero from "../../components/newsHero";
import NewsItem from "../../components/newsItem";
import Pagination from "../../components/pagination";
import { useDispatch, useSelector } from "react-redux";
import { retrieveNewsData } from "../../store/newsSlice";
import "./styles.sass";

const NewsPage = () => {
  const dispatch = useDispatch();
  const { newsData, isLoading, error, status } = useSelector((state) => state?.news);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveNewsData());
    }
  }, [dispatch, status]);

  console.log(newsData);

  const newsBackground = newsData?.background;

  const totalPages = Math.ceil(newsData?.news?.length / itemsPerPage);

  const currentNewsData = newsData?.news?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div className='newsPage'>
      <NewsHero newsBackground={newsBackground} />
      <div className='newsItems'>
        <div className='container'>
          <div className='newsItemsContainer'>
            {currentNewsData?.length >= 0 &&
              currentNewsData?.map((item, index) => (
                <NewsItem
                  key={index}
                  newsData={item}
                />
              ))}
          </div>
          {currentNewsData?.length >= 0 ? (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
