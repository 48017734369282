import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.sass";
import YoutubeVideoComponent from "../youtubeVideoComponent";

const ArticleContent = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  console.log(props?.newArticleData)
  return (
    <section className='articleContent'>
      <div className='container'>
        <div className='articleContainer'>
          <div className='contentImage'>
            {props?.newArticleData?.image && (
              <img
                src={props?.newArticleData?.image}
                alt='articleContentImage'
                className='img'
              />
            )}
            {props?.newArticleData?.video && (
              <YoutubeVideoComponent
                  videoId={props?.newArticleData?.video}
                  className={"video"}
              />
            )}
          </div>
          <div className='contentItems'>
            {props?.newArticleData?.title?.[lang] && (
              <div className='contentTitle'>
                <h3 className='title'>{props?.newArticleData?.title?.[lang]}</h3>
              </div>
            )}
            {props?.newArticleData?.content?.[lang] && (
              <div className='contentText'>
                <p
                  className='text'
                  dangerouslySetInnerHTML={{ __html: props?.newArticleData?.content?.[lang] }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArticleContent;
