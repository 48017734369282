import React from "react";
import logo from "../../assets/aboutLogo.png";
import "./styles.sass";
import { useTranslation } from "react-i18next";

const AboutSecondBlock = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  return (
    <section className='aboutSection'>
      {props?.divided_section?.left?.[lang] && (
        <div className='aboutMainBlock'>
          <div className='container'>
            <div className='aboutText'>
              {props?.divided_section?.left?.[lang]}
              {/* <p className='text'>{t("blocks.pageAbout.aboutSecondBlock.leftText")}</p> */}
            </div>
          </div>
        </div>
      )}
      <div className='aboutSectionLogo'>
        <img
          src={logo}
          alt='logo'
          className='img'
        />
      </div>
      {props?.divided_section?.right?.[lang] && (
        <div className='aboutBlock'>
          <div className='container'>
            <div className='aboutText'>
              {props?.divided_section?.right?.[lang]}
              {/* <p className='text'>{t("blocks.pageAbout.aboutSecondBlock.rightText")}</p> */}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default AboutSecondBlock;
