import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../utils/baseUrl";

export const retrieveAboutData = createAsyncThunk(
    'about/retrieveAboutData',
    async (rejectWithValue) => {
        try {
            const response = await axios.get(`${API_BASE}/api/data/about`);
            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response ? error.response.data : "Unknown error"
            )
        }
    }
)

const aboutSlice = createSlice({
    name: 'about',
    initialState:{
        isLoading: false,
        error: null,
        status: 'idle',
        aboutData: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveAboutData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.status = 'loading';
            })
            .addCase(retrieveAboutData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.status = 'succeeded';
                state.aboutData = action.payload;
            })
            .addCase(retrieveAboutData.rejected, (state, action) => {
                state.isLoading = false;
                state.status = 'failed';
                state.error = action.payload
            })
    }
})

export default aboutSlice.reducer