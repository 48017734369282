import React from "react";
import heroImage from "../../assets/introHero.png";
import Button from "../button";
import { useTranslation } from "react-i18next";
import "./styles.sass";

const MainHero = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  return (
    <section className='hero'>
      {props?.main_bg && (
        <div className='heroImage'>
          <img
            src={props?.main_bg}
            alt='heroImage'
            className='img'
          />
        </div>
      )}
      <div className='mainHero'>
        <div className='mainHeroContainer'>
          <div className='container'>
            <div className='heroContent'>
              {props?.main_title?.[lang] && (
                <div className='heroTitle'>
                  <h1 className='title'>{props?.main_title?.[lang]}</h1>
                  {/* <h1 className='title'>{t("blocks.mainHero.heroTitle")}</h1> */}
                </div>
              )}
              {props?.main_text?.[lang] && (
                <div className='heroSubtitle'>
                  <p className='subtitle'>{props?.main_text?.[lang]}</p>
                  {/* <p className='subtitle'>{t("blocks.mainHero.heroSubtitle")}</p> */}
                </div>
              )}
              <div className='heroCallBtn'>
                <a
                  href='#contact'
                  className='link'
                >
                  <Button>{t("blocks.buttons.contactUs")}</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainHero;
