import React, { useEffect } from "react";
import "./styles.sass";
import ResourcesHero from "../../components/resourcesHero";
import ResourcesCards from "../../components/resourcesCards";
import { useDispatch, useSelector } from "react-redux";
import { retrieveResourcesData } from "../../store/resources";

const Resources = () => {
  const dispatch = useDispatch();
  const { resourcesData, status, error, isLoading } = useSelector((status) => status?.resources);

  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveResourcesData());
    }
  }, [dispatch, status]);

  const heroSectionData = { background: resourcesData?.background, text: resourcesData?.text };
  const resourcesDataArr = resourcesData?.resources;
  return (
    <div className='resources'>
      <ResourcesHero heroSectionData={heroSectionData} />
      <ResourcesCards resourcesDataArr={resourcesDataArr} />
    </div>
  );
};

export default Resources;
