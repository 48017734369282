import React from "react";
import { useTranslation } from "react-i18next";
import InternationalSlider from "./internationalSlider";
import "./styles.sass";

const International = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  return (
    <section className='international'>
      <div className='container'>
        <div className='internationalContainer'>
          <div className='internationalContent'>
            {props?.international_section?.title?.[lang] && (
              <div className='contentTitle'>
                {/* <h4 className='title'>{t("blocks.pageAbout.international.title")}</h4> */}
                <h4 className='title'>{props?.international_section?.title?.[lang]}</h4>
              </div>
            )}
            {props?.international_section?.text?.[lang] && (
              <div className='contentSubtitle'>
                <p className='subtitle'>{props?.international_section?.text?.[lang]}</p>
                {/* <p className='subtitle'>{t("blocks.pageAbout.international.subtitle")}</p> */}
              </div>
            )}
          </div>
          <div className='internationalSlider'>
            <InternationalSlider international_section={props?.international_section} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default International;
