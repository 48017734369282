import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveStaticData } from "../../store/mainSlice";
import { retrieveNewsData } from "../../store/newsSlice";
import MainHero from "../../components/mainHero";
import AboutSection from "../../components/aboutSection";
import Advantages from "../../components/advantages";
import NewsSection from "../../components/newsSection";
import ContactUs from "../../components/contactUs";

const MainPage = () => {
  const dispatch = useDispatch();
  const { staticData, status, isLoading, error } = useSelector((state) => state?.main);
  const { newsData } = useSelector((state) => state?.news);

  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveStaticData());
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveNewsData());
    }
  }, [dispatch, status]);

  function getFirstSixElements(newsData) {
    return newsData?.slice(0, 6);
  }
  const firstSix = getFirstSixElements(newsData?.news);
  console.log(staticData);

  const main_title = staticData?.main_section?.title;
  const main_text = staticData?.main_section?.text;
  const main_bg = staticData?.main_section?.background;
  const divided_section = staticData?.divided_section;
  const advantages = staticData?.advantages;

  return (
    <div>
      <MainHero
        main_title={main_title}
        main_text={main_text}
        main_bg={main_bg}
      />
      <AboutSection divided_section={divided_section} />
      <Advantages advantages={advantages} />
      {firstSix?.length > 0 && <NewsSection firstSix={firstSix} />}
      <ContactUs />
    </div>
  );
};

export default MainPage;
