import React, { useEffect } from "react";
import ArticleHero from "../../components/articleHero";
import ArticleContent from "../../components/articleContent";
import { useParams } from "react-router-dom";
// import { newsData } from "../../data";
import { useDispatch, useSelector } from "react-redux";
import { retrieveNewsData } from "../../store/newsSlice";

const Article = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { newsData, isLoading, error, status } = useSelector((state) => state?.news);
  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveNewsData());
    }
  }, [dispatch, status]);

  const articleData = newsData?.news;
  const newArticleData = articleData?.find((article) => article.id === Number(id));

  return (
    <div className='article'>
      <ArticleHero newArticleData={newArticleData} />
      <ArticleContent newArticleData={newArticleData} />
    </div>
  );
};

export default Article;
