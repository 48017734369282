import React from "react";
import { GoGraph } from "react-icons/go";
import { MdOutlineContactMail } from "react-icons/md";
import { IoReload } from "react-icons/io5";
import { FaRegBookmark } from "react-icons/fa6";
import { MdDesignServices } from "react-icons/md";
import { PiFlag } from "react-icons/pi";

import advantages from "../../assets/2.jpg";

import { useTranslation } from "react-i18next";

import "./styles.sass";

const Advantages = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  // const advantagesItems = [
  //   { id: 1, icon: <GoGraph />, text: t("blocks.advantages.advantagesOne") },
  //   { id: 2, icon: <MdOutlineContactMail />, text: t("blocks.advantages.advantagesTwo") },
  //   { id: 3, icon: <IoReload />, text: t("blocks.advantages.advantagesThree") },
  //   { id: 4, icon: <FaRegBookmark />, text: t("blocks.advantages.advantagesFour") },
  //   { id: 5, icon: <MdDesignServices />, text: t("blocks.advantages.advantagesFive") },
  //   { id: 6, icon: <PiFlag />, text: t("blocks.advantages.advantagesSix") },
  // ];

  return (
    <section className='advantages'>
      <div className='container'>
        <div className='advantagesContainer'>
          <div className='advantagesContent'>
            <div className='contentHeading'>
              <div className='advantagesTitle'>
                <h2 className='title'> {t("blocks.advantages.title")}</h2>
              </div>
              {props?.advantages?.text?.[lang] && (
                <div className='advantagesSubtitle'>
                  <p className='subtitle'>{props?.advantages?.text?.[lang]}</p>
                </div>
              )}
            </div>
            <div className='contentAdvantages'>
              {props?.advantages?.advantages?.length > 0 &&
                props?.advantages?.advantages?.map((item, index) => (
                  <div
                    className='advantagesItems'
                    key={index}
                  >
                    <div className='itemIcon'>
                      {" "}
                      <img
                        className='imageIcon'
                        src={item?.icon}
                        alt='icon'
                      />
                    </div>
                    <div className='itemText'>
                      <p className='text'> {item?.title?.[lang]}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className='advantagesImagesItems'>
            <div className='itemImage'>
              <img
                src={advantages}
                alt='advantages'
                className='img'
              />
            </div>
            <div className='itemImageBg'></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
