import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../pagination";
import "./styles.sass";

const ResourcesCards = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;

  const totalPages = Math.ceil(props?.resourcesDataArr?.length / itemsPerPage);

  const currentResourcesData = props?.resourcesDataArr?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='resourcesCards'>
      <div className='container'>
        <div className='resourcesCardsContainer'>
          {props?.resourcesDataArr?.length > 0 &&
            props?.resourcesDataArr?.map((item, index) => (
              <div className='card'>
                <Link
                  className='cardLink'
                  to={`/resourcesInner/${item?.id}`}
                >
                  <div className='cardContainer'>
                    {item?.cover && (
                      <div className='cardImage'>
                        <img
                          className='img'
                          src={item?.cover}
                          alt='cardImage'
                        />
                      </div>
                    )}
                    <div className='cardContent'>
                      {item?.title?.[lang] && (
                        <div className='contentTitle'>
                          <h5 className='title'>{item?.title?.[lang]}</h5>
                        </div>
                      )}
                      {item?.preview_content?.[lang] && (
                        <div className='contentText'>
                          <p className='text'>{item?.preview_content?.[lang]}</p>
                        </div>
                      )}
                      <div className='contentLinkMore'>
                        <Link
                          className='link'
                          to={`/resourcesInner/${item?.id}`}
                        >
                          {t("blocks.news.seeMore")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        {props?.resourcesDataArr?.length > 0 && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ResourcesCards;
