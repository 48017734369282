import React from "react";
import logo from "../../assets/aboutLogo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./styles.sass";

const AboutSection = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  return (
    <section className='aboutSection'>
      <div className='aboutMainBlock'>
        {props?.divided_section?.left?.[lang] && (
          <div className='container'>
            <div className='aboutText'>
              <p className='text'>{props?.divided_section?.left?.[lang]}</p>
              {/* <p className='text'>{t("blocks.mainAbout.mainAboutLeft")}</p> */}
            </div>
          </div>
        )}
      </div>
      <div className='aboutSectionLogo'>
        <img
          src={logo}
          alt='logo'
          className='img'
        />
      </div>
      <div className='aboutBlock'>
        {props?.divided_section?.right?.[lang] && (
          <div className='container'>
            <div className='aboutText'>
              <p className='text'>{props?.divided_section?.right?.[lang]}</p>
              {/* <p className='text'>{t("blocks.mainAbout.mainAboutRight")}</p> */}
            </div>
            <Link
              to='/about'
              className='link'
            >
              {t("blocks.buttons.additionalInfo")}
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default AboutSection;
