import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.sass";

const AboutHero = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  return (
    <div className='aboutHero'>
      {props?.main_section?.background && (
        <div className='aboutHeroImage'>
          <img
            src={props?.main_section?.background}
            alt='aboutHero'
            className='img'
          />
        </div>
      )}
      <div className='aboutHeroContent'>
        <div className='container'>
          <div className='contentContainer'>
            {props?.main_section?.title?.[lang] && (
              <div className='contentTitle'>
                <h2 className='title'>{props?.main_section?.title?.[lang]}</h2>
              </div>
            )}
            {props?.main_section?.text?.[lang] && (
              <div className='contentSubtitle'>
                <p className='subtitle'>{props?.main_section?.text?.[lang]}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
