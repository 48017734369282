import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../utils/baseUrl";

export const retrieveStaticData = createAsyncThunk(
    'main/retrieveStaticData',
    async (rejectWithValue) => {
        try {
            const response = await axios.get(`${API_BASE}/api/data/static`);
            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response ? error.response.data : "Unknown error"
            )
        }
    }
)

const mainSlice = createSlice({
    name: 'main',
    initialState:{
        isLoading: false,
        error: null,
        status: 'idle',
        staticData: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveStaticData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.status = 'loading';
            })
            .addCase(retrieveStaticData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.status = 'succeeded';
                state.staticData = action.payload;
            })
            .addCase(retrieveStaticData.rejected, (state, action) => {
                state.isLoading = false;
                state.status = 'failed';
                state.error = action.payload
            })
    }
})

export default mainSlice.reducer