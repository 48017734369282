import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./styles.sass";

const ProductItem = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  return (
    <div className='productItem'>
      <div className='cardContainer'>
        <div className='cardContent'>
          {props?.productItem?.cover && (
            <div className='cardImage'>
              <img
                src={props?.productItem?.cover}
                alt='image'
                className='img'
              />
            </div>
          )}
          {props?.productItem?.title?.[lang] && (
            <div className='contentTitle'>
              <h4 className='title'>{props?.productItem?.title?.[lang]}</h4>
            </div>
          )}
          {props?.productItem?.short_description?.[lang] && (
            <div className='contentText'>
              <div
                className='text'
                dangerouslySetInnerHTML={{ __html: props?.productItem?.short_description?.[lang] }}
              />
            </div>
          )}
        </div>
        <div className='contentSeeMore'>
          <Link
            to={`/product/${props?.productItem?.id}`}
            className='link'
          >
            {t("blocks.product.seeAll")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
