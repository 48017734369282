import React from "react";
import { useTranslation } from "react-i18next";
import certificatesImage from "../../assets/certificatesImage.png";
import "./styles.sass";

const Certificates = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  return (
    <section className='certificates'>
      <div className='container'>
        <div className='certificatesContainer'>
          <div className='certificatesContent'>
            {props?.certificates_section?.title?.[lang] && (
              <div className='contentTitle'>
                <h3 className='title'>{props?.certificates_section?.title?.[lang]}</h3>
                {/* <h3 className='title'>{t("blocks.pageAbout.certificates.title")}</h3> */}
              </div>
            )}
            {props?.certificates_section?.text?.[lang] && (
              <div className='contentSubtitle'>
                {/* <p className='subtitle'>{t("blocks.pageAbout.certificates.subtitle")}</p> */}
                <p className='subtitle'>{props?.certificates_section?.text?.[lang]}</p>
              </div>
            )}
          </div>
          {props?.certificates_section?.image && (
            <div className='certificatesImage'>
              <img
                src={props?.certificates_section?.image}
                alt='certificatesImage'
                className='img'
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Certificates;
