import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE } from "../utils/baseUrl";

export const retrieveResourcesData = createAsyncThunk(
    'resources/retrieveResourcesData',
    async (rejectWithValue) => {
        try {
            const response = await axios.get(`${API_BASE}/api/data/resources`);
            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response ? error.response.data : "Unknown error"
            )
        }
    }
)

const resourcesSlice = createSlice({
    name: 'resources',
    initialState:{
        isLoading: false,
        error: null,
        status: 'idle',
        resourcesData: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveResourcesData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.status = 'loading';
            })
            .addCase(retrieveResourcesData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.status = 'succeeded';
                state.resourcesData = action.payload;
            })
            .addCase(retrieveResourcesData.rejected, (state, action) => {
                state.isLoading = false;
                state.status = 'failed';
                state.error = action.payload
            })
    }
})

export default resourcesSlice.reducer