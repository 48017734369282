import React from "react";
import projectInnerImage from "../../assets/projectInnerImage.png";
import "./styles.sass";
import { useTranslation } from "react-i18next";

const ProjectContent = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  return (
    <section className='projectContent'>
      <div className='container'>
        <div className='projectContentContainer'>
          <div className='projectContentInfo'>
            <div className='projectContentInfoContainer'>
              {props?.newProductData?.title?.[lang] && (
                <div className='projectContentTitle'>
                  <h4 className='title'>{props?.newProductData?.title?.[lang]}</h4>
                </div>
              )}
              {props?.newProductData?.description?.[lang] && (
                <div className='infoText'>
                  <div
                    className='text'
                    dangerouslySetInnerHTML={{ __html: props?.newProductData?.description?.[lang] }}
                  />
                </div>
              )}
            </div>
            {props?.newProductData?.image && (
              <div className='infoImage'>
                <img
                  src={props?.newProductData?.image}
                  className='img'
                  alt='projectInnerImage'
                />
              </div>
            )}
          </div>
          <div className='description'>
            <div className='descriptionContainer'>
              <div className='descriptionTitle'>
                <h5 className='title'>Характеристики</h5>
              </div>
              <div className='descriptionList'>
                <div
                  className='descriptionListItems'
                  dangerouslySetInnerHTML={{ __html: props?.newProductData?.characteristics?.[lang] }}
                />
              </div>
            </div>
            <div className='descriptionContainer'>
              <div className='descriptionTitle'>
                <h5 className='title'>Дополнительная информация</h5>
              </div>
              <div className='descriptionList'>
                <div
                  className='descriptionListItems'
                  dangerouslySetInnerHTML={{ __html: props?.newProductData?.additional?.[lang] }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectContent;
