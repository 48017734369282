import React, { useEffect } from "react";
import ProjectsHero from "../../components/projectsHero";
import ProductsContent from "../../components/productsContent";
import ContactUs from "../../components/contactUs";
import { useDispatch, useSelector } from "react-redux";
import { retrieveProductsData } from "../../store/productsSlice";

const Products = () => {
  const dispatch = useDispatch();
  const { productsData, status, isLoading, error } = useSelector((state) => state?.products);

  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveProductsData());
    }
  }, [dispatch, status]);
  const imageBg = productsData?.background;
  const heroText = productsData?.text;
  const products = productsData?.products;
  return (
    <div className='products'>
      <ProjectsHero
        heroText={heroText}
        imageBg={imageBg}
      />
      <ProductsContent products={products} />
      <ContactUs />
    </div>
  );
};

export default Products;
