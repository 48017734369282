import { configureStore } from "@reduxjs/toolkit";
import contactsSlice from './contactsSlice.js';
import newsSlice from './newsSlice.js'
import aboutSlice from './aboutSlice.js'
import productsSlice from './productsSlice.js'
import mainSlice from './mainSlice.js'
import resourcesSlice from './resources.js'

export const store = configureStore({
    reducer: {
        contacts: contactsSlice,
        news: newsSlice,
        about: aboutSlice,
        products: productsSlice,
        main: mainSlice,
        resources: resourcesSlice
    }
})