import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.sass";

const ResourcesHero = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  return (
    <div className='resourcesHero'>
      {props?.heroSectionData?.background && (
        <div className='resourcesHeroImage'>
          <img
            src={props?.heroSectionData?.background}
            alt='resourcesHero'
            className='img'
          />
        </div>
      )}
      <div className='resourcesHeroContent'>
        <div className='container'>
          <div className='contentContainer'>
            <div className='contentTitle'>
              <h2 className='title'>{t("blocks.navigation.resources")}</h2>
            </div>
            {props?.heroSectionData?.text?.[lang] && (
              <div className='contentSubtitle'>
                <p className='subtitle'>{props?.heroSectionData?.text?.[lang]}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesHero;
