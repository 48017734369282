import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveResourcesData } from "../../store/resources";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResourcesInnerHero from "../../components/resourcesInnerHero";
import "./styles.sass";

const ResourcesInner = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { resourcesData, status, error, isLoading } = useSelector((state) => state?.resources);

  useEffect(() => {
    if (status === "idle") {
      dispatch(retrieveResourcesData());
    }
  }, [dispatch, status]);

  const resourcesInnerData = resourcesData?.resources;
  const newResourcesData = resourcesInnerData?.find((resources) => resources?.id === Number(id));
  const resourcesHero = {
    title: newResourcesData?.title,
    cover: newResourcesData?.cover,
  };
  return (
    <div className='resourcesInner'>
      <ResourcesInnerHero resourcesHero={resourcesHero} />
      <div className='container'>
        <div className='resourcesInnerContainer'>
          {newResourcesData?.video && (
            <div className='resourcesInnerVideo'>
              <video
                controls
                className='video'
              >
                <source
                  src={newResourcesData?.video}
                  type='video/mp4'
                />
              </video>
            </div>
          )}
          <div className='resourcesInnerContent'>
            {newResourcesData?.title?.[lang] && (
              <div className='contentTitle'>
                <h4 className='title'>{newResourcesData?.title?.[lang]}</h4>
              </div>
            )}
            {newResourcesData?.content?.[lang] && (
              <div className='contentText'>
                <div
                  className='text'
                  dangerouslySetInnerHTML={{ __html: newResourcesData?.content?.[lang] }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesInner;
