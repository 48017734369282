import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE } from "../utils/baseUrl";
import axios from "axios";

export const retrieveProductsData = createAsyncThunk(
    'products/retrieveProductsData',
    async (rejectWithValue) => {
        try {
            const response = await axios.get(`${API_BASE}/api/data/products`);
            return response.data
        } catch (error) {
            return rejectWithValue(
                error.response ? error.response.data : "Unknown error"
            )
        }
    }
)

export const postReviewsData = createAsyncThunk(
    'products/postReviewData',
    async (reviewsData, thunkApi) => {
        try{
            const response = await axios.post(`${API_BASE}/api/feedback/send`, reviewsData);
            return response.data
        } catch (error){
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
)

const productsSlice = createSlice({
    name: 'products',
    initialState:{
        isLoading: false,
        error: null,
        status: 'idle',
        productsData: null
    },
    reducers:{
        resetFormState: (state) => {
            state.isLoading = false;
            state.success = false;
            state.error = null;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postReviewsData.pending, (state) => {
                state.isLoading = true;
                state.status = "loading";
            })
            .addCase(postReviewsData.fulfilled, (state) => {
                state.isLoading = false;
                state.status = "succeeded";
                state.success = true;
            })
            .addCase(postReviewsData.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(retrieveProductsData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.status = 'loading';
            })
            .addCase(retrieveProductsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.status = 'succeeded';
                state.productsData = action.payload;
            })
            .addCase(retrieveProductsData.rejected, (state, action) => {
                state.isLoading = false;
                state.status = 'failed';
                state.error = action.payload
            })
    }
})

export const {resetFormState} = productsSlice.actions
export default productsSlice.reducer